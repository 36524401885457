import { NcListItem, NcActionButton } from '@nextcloud/vue';
import { translate as t } from '../l10n';
import ContentDuplicate from 'vue-material-design-icons/ContentDuplicate.vue';
export default {
    name: 'ScriptCard',
    components: {
        NcListItem,
        NcActionButton,
        ContentDuplicate,
    },
    props: {
        script: Object,
    },
    methods: {
        t,
        enabledText() {
            return this.script.enabled ? t('files_scripts', 'Enabled') : t('files_scripts', 'Disabled');
        },
        editScript() {
            this.$emit('select', this.script);
        },
        duplicateScript() {
            this.$emit('duplicate', this.script);
        },
        deleteScript() {
            this.$emit('delete', this.script);
        },
    },
};
