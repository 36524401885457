import axios from '@nextcloud/axios';
import { generateUrl } from '@nextcloud/router';
import { inflateScriptInputOptions } from '../types/script';
export const api = {
    /**
     * Get the scripts that are enabled for the current user.
     */
    async getScripts() {
        return (await axios.get(generateUrl('/apps/files_scripts/scripts'))).data;
    },
    /**
     * Get all scripts (admin only).
     */
    async getAllScripts() {
        return (await axios.get(generateUrl('/apps/files_scripts/scripts/all'))).data;
    },
    async createScript(script) {
        return (await axios.post(generateUrl('/apps/files_scripts/scripts'), script)).data;
    },
    async updateScript(script) {
        return (await axios.put(generateUrl('/apps/files_scripts/scripts/' + script.id), script)).data;
    },
    async deleteScript(script) {
        return (await axios.delete(generateUrl('/apps/files_scripts/scripts/' + script.id))).data;
    },
    async runScript(script, inputs, files) {
        var _a, _b;
        const shareToken = (_b = (_a = document.getElementById('sharingToken')) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null;
        const data = { inputs, files, shareToken };
        return (await axios.post(generateUrl('/apps/files_scripts/run/' + script.id), data)).data;
    },
    async getScriptInputs(scriptId) {
        return (await axios.get(generateUrl('/apps/files_scripts/script_inputs/' + scriptId))).data
            .map((scriptInput) => {
            return inflateScriptInputOptions(scriptInput);
        });
    },
    async updateScriptInputs(script, scriptInputs) {
        return (await axios.post(generateUrl('/apps/files_scripts/script_inputs/' + script.id), { scriptInputs })).data;
    },
};
