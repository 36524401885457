import { loadState } from '@nextcloud/initial-state';
import { showError } from '@nextcloud/dialogs';
import Plus from 'vue-material-design-icons/Plus.vue';
import FileCog from 'vue-material-design-icons/FileCog.vue';
import { NcNoteCard, NcButton, NcEmptyContent, NcSettingsSection, NcCheckboxRadioSwitch } from '@nextcloud/vue';
import ScriptEdit from '../components/ScriptEdit.vue';
import ScriptCard from '../components/ScriptCard.vue';
import { mapState } from 'vuex';
import { translate as t } from '../l10n';
import axios from "@nextcloud/axios";
import { generateUrl } from "@nextcloud/router";
import packageInfo from "../../package.json";
export default {
    name: 'Settings',
    components: {
        NcNoteCard,
        NcButton,
        NcEmptyContent,
        NcSettingsSection,
        NcCheckboxRadioSwitch,
        ScriptEdit,
        ScriptCard,
        Plus,
        FileCog,
        packageInfo,
    },
    computed: Object.assign(Object.assign({}, mapState({
        selectedScript: 'selectedScript',
        scripts: 'scripts',
    })), { isLoading() {
            return this.scripts === null;
        } }),
    data() {
        return {
            usePhpInterpreter: loadState('files_scripts', 'use_php_interpreter', false),
            pluginAvailable: loadState('files_scripts', 'lua_plugin_available', false),
            docUrl: `https://github.com/Raudius/files_scripts/blob/v${packageInfo.version}/docs/Admin.md`
        };
    },
    mounted() {
        this.$store.dispatch('fetchAllScripts');
    },
    methods: {
        t,
        newScript() {
            this.$store.commit('newScript');
        },
        selectScript(script) {
            this.$store.commit('setSelectedScript', script);
        },
        duplicateScript(script) {
            try {
                this.$store.dispatch('duplicateScript', script);
            }
            catch (e) {
                showError(t('files_scripts', 'Failed to duplicate the script.'));
            }
        },
        deleteScript(script) {
            try {
                this.$store.dispatch('deleteScript', script);
            }
            catch (e) {
                showError(t('files_scripts', 'Failed to delete the script.'));
            }
        },
        async toggleExperimentalInterpreter(value) {
            this.usePhpInterpreter = value;
            try {
                await axios.post(generateUrl('/apps/files_scripts/settings'), {
                    name: 'php_interpreter',
                    value: value ? 'true' : 'false'
                });
            }
            catch (error) {
                showError(error.response.data.error);
                this.usePhpInterpreter = !value;
            }
        },
    },
};
