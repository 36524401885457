import Save from 'vue-material-design-icons/ContentSave.vue';
import { NcModal, NcActions, NcActionButton, NcCheckboxRadioSwitch, NcNoteCard, NcSelect, NcTextField } from '@nextcloud/vue';
import FreeSelect from './generic/FreeSelect.vue';
import EditInputs from './ScriptEdit/EditInputs.vue';
import 'codemirror/mode/lua/lua.js';
import 'codemirror/addon/edit/matchbrackets.js';
import 'codemirror/addon/hint/show-hint.js';
import { mapState } from 'vuex';
import { showError, showSuccess } from '@nextcloud/dialogs';
import { api } from '../api/script';
import { translate as t } from '../l10n';
import axios from '@nextcloud/axios';
import { generateOcsUrl } from '@nextcloud/router';
const CodeMirror = require('vue-codemirror').codemirror;
export default {
    name: 'ScriptEdit',
    components: {
        axios,
        NcModal,
        NcActions,
        NcActionButton,
        CodeMirror,
        NcCheckboxRadioSwitch,
        NcNoteCard,
        NcSelect,
        Save,
        EditInputs,
        FreeSelect,
        NcTextField,
    },
    data() {
        const mainBackgroundColor = getComputedStyle(document.querySelector('body'))
            .getPropertyValue('--color-main-background-rgb')
            .split(',');
        const darkMode = Number(mainBackgroundColor[0]) < 128
            && Number(mainBackgroundColor[1]) < 128
            && Number(mainBackgroundColor[2]) < 128;
        return {
            scriptInputs: [],
            groups: [],
            dirtyInputs: false,
            saving: false,
            limitGroupsEnabled: false,
            limitMimesEnabled: false,
            cmOption: {
                tabSize: 4,
                styleActiveLine: true,
                lineNumbers: true,
                fixedGutter: false,
                line: true,
                foldGutter: false,
                styleSelectedText: true,
                matchBrackets: true,
                showCursorWhenSelecting: true,
                mode: 'text/x-lua',
                theme: darkMode ? 'material-darker' : 'idea',
                extraKeys: { Ctrl: 'autocomplete' },
                hintOptions: {
                    completeSingle: true,
                },
            },
        };
    },
    computed: Object.assign(Object.assign({}, mapState({ script: 'selectedScript' })), { showModal() {
            return !!this.script;
        }, scriptTitle: {
            get() {
                return this.script.title;
            },
            set(value) {
                this.$store.commit('updateCurrentScript', { title: value });
            },
        }, scriptProgram: {
            get() {
                return this.script.program;
            },
            set(value) {
                this.$store.commit('updateCurrentScript', { program: value });
            },
        }, scriptDescription: {
            get() {
                return this.script.description;
            },
            set(value) {
                this.$store.commit('updateCurrentScript', { description: value });
            },
        }, limitGroups: {
            get() {
                return this.script ? this.script.limitGroups : [];
            },
            set(value) {
                this.$store.commit('updateCurrentScript', { limitGroups: value });
            },
        }, fileTypes: {
            get() {
                return this.script ? this.script.fileTypes : "";
            },
            set(value) {
                this.$store.commit('updateCurrentScript', { fileTypes: value });
            },
        } }),
    mounted() {
        this.loadGroups();
        document.addEventListener("keydown", this.doSaveKeyboardShortcut);
    },
    beforeDestroy() {
        document.removeEventListener("keydown", this.doSaveKeyboardShortcut);
    },
    watch: {
        showModal(newValue) {
            newValue && this.remounted();
            // Hack to fix codemirror rendering issue
            if (newValue === true) {
                setTimeout(() => {
                    const evt = document.createEvent('UIEvents');
                    evt.initUIEvent('resize', true, false, window, 0);
                    window.dispatchEvent(evt);
                }, 500);
            }
        },
    },
    methods: {
        t,
        remounted() {
            this.limitGroupsEnabled = this.limitGroups.length > 0;
            this.limitMimesEnabled = this.fileTypes.length > 0;
        },
        doSaveKeyboardShortcut(e) {
            const ctrlS = (e.keyCode === 83 && e.ctrlKey);
            if (this.showModal && ctrlS) {
                e.preventDefault();
                this.saveScript();
            }
        },
        saveScript() {
            const self = this;
            this.saving = true;
            this.saveScriptAsync()
                .then(() => {
                self.dirtyInputs = false;
                showSuccess(t('files_scripts', 'Saved'), { timeout: 2000 });
            })
                .catch((error) => {
                let message = t('files_scripts', 'An error occurred during saving');
                if (error.response && error.response.data.error) {
                    message = error.response.data.error;
                }
                showError(message);
            })
                .then(() => {
                this.saving = false;
            });
        },
        async saveScriptAsync() {
            await this.$store.dispatch('saveScript');
            if (this.dirtyInputs) {
                await api.updateScriptInputs(this.script, this.scriptInputs);
            }
        },
        updateInputs(scriptInputs) {
            this.scriptInputs = scriptInputs;
            this.dirtyInputs = true;
        },
        closeModal() {
            this.$store.commit('clearSelected');
            this.dirtyInputs = false;
            this.scriptInputs = [];
        },
        toggleEnabled() {
            this.$store.commit('selectedToggleValue', 'enabled');
        },
        toggleShowInContext() {
            this.$store.commit('selectedToggleValue', 'showInContext');
        },
        togglePublic() {
            this.$store.commit('selectedToggleValue', 'public');
        },
        toggleLimitGroupsEnabled() {
            if (!this.limitGroupsEnabled) {
                this.limitGroups = [];
            }
        },
        toggleLimitMimes() {
            if (!this.limitMimesEnabled) {
                this.mimetype = "";
            }
        },
        async loadGroups() {
            const response = await axios.get(generateOcsUrl('cloud/groups'));
            if (response && response.data && response.data.ocs) {
                this.groups = response.data.ocs.data.groups;
            }
        }
    },
};
