import FormTextbox from 'vue-material-design-icons/FormTextbox.vue';
import FormSelect from 'vue-material-design-icons/FormSelect.vue';
import CheckboxMultipleMarkedCircle from 'vue-material-design-icons/CheckboxMultipleMarkedCircle.vue';
import Folder from 'vue-material-design-icons/Folder.vue';
import { NcCheckboxRadioSwitch, NcSelect, NcTextField } from '@nextcloud/vue';
import { FilePickerBuilder, showError } from "@nextcloud/dialogs";
import { translate as t } from '../../l10n';
import * as path from 'path';
export default {
    name: 'ScriptInputComponent',
    props: {
        scriptInput: Object,
        outputDirectory: String
    },
    components: {
        FormTextbox,
        FormSelect,
        CheckboxMultipleMarkedCircle,
        Folder,
        NcCheckboxRadioSwitch,
        NcSelect,
        NcTextField
    },
    data() {
        return {
            localValue: this.scriptInput.value
        };
    },
    mounted() {
        this.resetValue();
    },
    computed: {
        type() {
            return this.scriptInput.options.type;
        },
        isInputCheckbox() {
            return this.type === 'checkbox';
        },
        isInputMultiselect() {
            return this.type === 'multiselect';
        },
        isInputFilepick() {
            return this.type === 'filepick';
        },
    },
    methods: {
        t,
        resetValue() {
            this.localValue = this.getDefaultValue();
        },
        getDefaultValue() {
            switch (this.type) {
                case 'checkbox':
                    return false;
                case 'filepick':
                    return this.scriptInput.options.filepickMimes.includes('httpd/unix-directory')
                        ? this.outputDirectory
                        : '';
                default: return '';
            }
        },
        async filepick() {
            const mimetypes = this.scriptInput.options.filepickMimes;
            const allowDirectories = mimetypes.includes('httpd/unix-directory');
            const pickerBuiler = (new FilePickerBuilder(this.scriptInput.description))
                .allowDirectories(allowDirectories)
                .setMultiSelect(false)
                .startAt(this.outputDirectory)
                .setType(1); // TODO replace with "add button" method, see example below
            /*.addButton({
                label: 'Pick',
                callback: (nodes) => console.log('Picked', nodes),
            })*/
            if (mimetypes.length > 0) {
                pickerBuiler.setMimeTypeFilter(mimetypes);
            }
            const picker = pickerBuiler.build();
            try {
                const dir = await picker.pick() || '/';
                this.localValue = path.normalize(dir);
            }
            catch (error) {
                showError(error.message || t('files_scripts', 'Unknown error'));
            }
        },
    },
    watch: {
        localValue(newValue) {
            this.scriptInput.value = newValue;
        }
    }
};
