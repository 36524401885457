/**
 * Generates an empty Script.
 */
export function defaultScript() {
    return {
        id: null,
        title: '',
        description: '',
        enabled: false,
        program: '',
        public: false,
        limitGroups: [],
        mimetype: '',
        fileTypes: [],
        showInContext: false
    };
}
export function createScriptInput(name, description) {
    return {
        name,
        description,
        options: defaultScriptInputOptions()
    };
}
export function inflateScriptInputOptions(scriptInput) {
    var _a, _b, _c, _d, _e;
    const defaultOptions = defaultScriptInputOptions();
    return Object.assign(Object.assign({}, scriptInput), { options: {
            type: (_a = scriptInput.options.type) !== null && _a !== void 0 ? _a : defaultOptions.type,
            multiselectOptions: (_b = scriptInput.options.multiselectOptions) !== null && _b !== void 0 ? _b : defaultOptions.multiselectOptions,
            filepickMimes: (_c = scriptInput.options.filepickMimes) !== null && _c !== void 0 ? _c : defaultOptions.filepickMimes,
            allowMultiple: (_d = scriptInput.options.allowMultiple) !== null && _d !== void 0 ? _d : defaultOptions.allowMultiple,
            textarea: (_e = scriptInput.options.textarea) !== null && _e !== void 0 ? _e : defaultOptions.textarea,
        } });
}
export function defaultScriptInputOptions() {
    return {
        type: 'text',
        multiselectOptions: [],
        filepickMimes: [],
        allowMultiple: false,
        textarea: false,
    };
}
export function scriptAllowedForNodes(script, nodes) {
    if (script.fileTypes.length === 0) {
        return true;
    }
    const scriptMimes = new Set(script.fileTypes);
    return nodes.every((node) => {
        if (scriptMimes.has(node.mime)) {
            return true;
        }
        const extension = node.basename.split(".").pop();
        return scriptMimes.has(extension) || scriptMimes.has("." + extension);
    });
}
