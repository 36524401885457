// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.free-select[data-v-05ce15dc] {
	width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/generic/FreeSelect.vue"],"names":[],"mappings":";AA8DA;CACA,WAAA;AACA","sourcesContent":["<template>\n\t<NcSelect\n\t\tclass=\"free-select\"\n\t\tv-model=\"selected\"\n\t\t@search=\"updateInput\"\n\t\t:placeholder=\"label\"\n\t\t:options=\"options\"\n\t\t:multiple=\"true\"\n\t\t:closeOnSelect=\"false\"\n\t\t:tag-width=\"80\"\n\t\t@option:selected=\"change\"\n\t\t@option:deselected=\"change\"\n\t>\n\t\t<template #no-options=\"{ search, searching, loading }\">\n\t\t\t{{ hint }}\n\t\t</template>\n\t</NcSelect>\n</template>\n\n<script lang=\"ts\">\nimport { NcSelect } from '@nextcloud/vue'\n\nexport default {\n\tname: 'FreeSelect',\n\tmodel: {\n\t\tprop: 'values',\n\t\tevent: 'change'\n\t},\n\tcomponents: {\n\t\tNcSelect\n\t},\n\tprops: {\n\t\tlabel: String,\n\t\thint: String,\n\t\tvalues: {\n\t\t\ttype: Array,\n\t\t\tdefault: () => []\n\t\t}\n\t},\n\tdata() {\n\t\treturn {\n\t\t\tselected: [...this.values],\n\t\t\toptions: []\n\t\t}\n\t},\n\tmethods: {\n\t\tupdateInput(input) {\n\t\t\tthis.options = !!(input.trim()) ? [input] : []\n\t\t},\n\t\tchange() {\n\t\t\tthis.$emit(\"change\", this.selected)\n\t\t}\n\t},\n\twatch: {\n\t\tvalues() {\n\t\t\tthis.selected = this.values\n\t\t}\n\t}\n}\n</script>\n\n<style scoped>\n.free-select {\n\twidth: 100%;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
