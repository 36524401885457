/**
 * Translates a string.
 *
 * @param {String} ctxt Translation context
 * @param {string} str String to be translated
 * @param {object} params Translation parameters
 */
export function translate(ctxt, str, params) {
    return t(ctxt, str, params);
}
