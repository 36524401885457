import { NcTextField, NcButton, NcSelect, NcCheckboxRadioSwitch } from '@nextcloud/vue';
import ContentSave from 'vue-material-design-icons/ContentSave.vue';
import KeyboardBackspace from 'vue-material-design-icons/KeyboardBackspace.vue';
import Plus from 'vue-material-design-icons/Plus.vue';
import { defaultScriptInputOptions } from '../../types/script';
import { translate as t } from '../../l10n';
import FreeSelect from '../generic/FreeSelect.vue';
const InputTypes = {
    text: { id: "text", label: t('files_scripts', 'Text') },
    checkbox: { id: "checkbox", label: t('files_scripts', 'Checkbox') },
    filepick: { id: "filepick", label: t('files_scripts', 'File picker') },
    multiselect: { id: "multiselect", label: t('files_scripts', 'Multi-select') },
};
export default {
    name: 'EditInputDetails',
    components: {
        NcTextField,
        NcButton,
        ContentSave,
        KeyboardBackspace,
        Plus,
        NcSelect,
        FreeSelect,
        NcCheckboxRadioSwitch
    },
    props: {
        scriptInput: Object,
    },
    mounted() {
        this.scriptInput.value = null;
    },
    data() {
        var _a;
        return {
            InputTypes,
            inputType: (_a = InputTypes[this.scriptInput.options.type]) !== null && _a !== void 0 ? _a : InputTypes.text,
        };
    },
    computed: {
        isInputMultiselect() {
            var _a;
            return ((_a = this.inputType) === null || _a === void 0 ? void 0 : _a.id) === 'multiselect';
        },
        isInputFilepick() {
            var _a;
            return ((_a = this.inputType) === null || _a === void 0 ? void 0 : _a.id) === 'filepick';
        },
        isInputText() {
            var _a;
            return this.inputType
                ? ((_a = this.inputType) === null || _a === void 0 ? void 0 : _a.id) === 'text'
                : true;
        },
        textarea: {
            get() {
                return this.scriptInput.options.textarea;
            },
            set(value) {
                this.scriptInput.options.textarea = value;
            }
        },
        allowMultiple: {
            get() {
                return this.scriptInput.options.allowMultiple;
            },
            set(value) {
                this.scriptInput.options.allowMultiple = value;
            }
        },
        options: {
            get() {
                var _a, _b;
                const options = this.scriptInput.options;
                if (!options) {
                    return [];
                }
                return this.isInputMultiselect
                    ? ((_a = this.scriptInput.options.multiselectOptions) !== null && _a !== void 0 ? _a : [])
                    : ((_b = this.scriptInput.options.filepickMimes) !== null && _b !== void 0 ? _b : []);
            },
            set(values) {
                if (this.isInputMultiselect) {
                    this.scriptInput.options.multiselectOptions = values;
                }
                if (this.isInputFilepick) {
                    this.scriptInput.options.filepickMimes = values;
                }
            }
        }
    },
    methods: {
        t,
        save() {
            this.$emit('save', this.scriptInput);
        },
        back() {
            this.$emit('cancel');
        },
        changeInputType(inputType) {
            this.scriptInput.options = Object.assign(Object.assign({}, defaultScriptInputOptions()), { type: inputType.id, filepickMimes: [], multiselectOptions: [] });
        }
    },
};
