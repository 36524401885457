import { defaultScript } from '../types/script';
import { api } from '../api/script';
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
const getters = {
    getEnabledScripts(state) {
        return (state.scripts && Array.isArray(state.scripts))
            ? state.scripts.filter(s => s.enabled)
            : null;
    },
};
const mutations = {
    setScripts(state, scripts) {
        state.scripts = scripts;
    },
    setSelectedScript(state, script) {
        state.selectedScript = script;
    },
    selectedToggleValue(state, value) {
        if (state.selectedScript && state.selectedScript.hasOwnProperty(value)) {
            state.selectedScript[value] = !state.selectedScript[value];
        }
    },
    newScript(state) {
        state.selectedScript = defaultScript();
    },
    clearSelected(state) {
        state.selectedScript = null;
    },
    clearAll(state) {
        state.scripts = null;
        state.selectedScript = null;
    },
    updateCurrentScript(state, newValues) {
        var _a, _b, _c, _d, _e, _f, _g;
        state.selectedScript = Object.assign(Object.assign({}, state.selectedScript), { title: (_a = newValues.title) !== null && _a !== void 0 ? _a : state.selectedScript.title, program: (_b = newValues.program) !== null && _b !== void 0 ? _b : state.selectedScript.program, description: (_c = newValues.description) !== null && _c !== void 0 ? _c : state.selectedScript.description, enabled: (_d = newValues.enabled) !== null && _d !== void 0 ? _d : state.selectedScript.enabled, limitGroups: (_e = newValues.limitGroups) !== null && _e !== void 0 ? _e : state.selectedScript.limitGroups, showInContext: (_f = newValues.showInContext) !== null && _f !== void 0 ? _f : state.selectedScript.showInContext, fileTypes: (_g = newValues.fileTypes) !== null && _g !== void 0 ? _g : state.selectedScript.fileTypes });
    },
};
const actions = {
    async fetchScripts({ commit }) {
        commit('setScripts', await api.getScripts());
    },
    async fetchAllScripts({ commit }) {
        commit('setScripts', await api.getAllScripts());
    },
    async saveScript({ dispatch, commit, state }) {
        const script = state.selectedScript;
        if (script.id) {
            await api.updateScript(script);
        }
        else {
            const newScript = await api.createScript(script);
            commit('setSelectedScript', newScript);
        }
        dispatch('fetchAllScripts');
    },
    async deleteScript({ dispatch, commit, state }, script) {
        commit('clearAll');
        if (script.id) {
            await api.deleteScript(script);
        }
        dispatch('fetchAllScripts');
    },
    async duplicateScript({ dispatch, commit, state }, script) {
        commit('clearAll');
        if (script.id) {
            script.title = script.title + " (copy)";
            await api.createScript(script);
        }
        dispatch('fetchAllScripts');
    },
};
export const store = new Vuex.Store({
    state: {
        loadingScriptId: null,
        scripts: null,
        selectedScript: null,
    },
    getters,
    mutations,
    actions,
});
