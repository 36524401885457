import { translate as t } from './l10n';
import { FileAction, registerFileAction } from '@nextcloud/files';
import { scriptAllowedForNodes } from "./types/script";
import FileCog from '@mdi/svg/svg/file-cog.svg';
import { emit } from "@nextcloud/event-bus";
export function reloadDirectory(node) {
    var _a, _b;
    node && emit("files:node:updated", node);
    // Legacy reload for public shares
    const fileList = (_b = (_a = OCA === null || OCA === void 0 ? void 0 : OCA.Sharing) === null || _a === void 0 ? void 0 : _a.PublicApp) === null || _b === void 0 ? void 0 : _b.fileList;
    if (!fileList) {
        return;
    }
    fileList.changeDirectory(fileList.getCurrentDirectory(), true, true);
}
function getNodeInfo(node) {
    return {
        id: node.fileid,
        basename: node.basename,
        fullPath: node.path,
        mime: node.mime
    };
}
function buildActionObject(myHandler, script = null) {
    const displayName = script ? script.title : t('files_scripts', 'More actions');
    const id = 'files_scripts_action' + (script ? script.id : "");
    const order = 1000 + (script ? 0 : 1);
    const handleExec = (handler, nodes, view, dir) => {
        const nodeInfos = nodes.map(getNodeInfo);
        if (!view) {
            handler(nodeInfos, null);
        }
        else {
            view.getContents(dir).then(function (value) {
                myHandler(nodeInfos, value.folder);
            });
        }
    };
    return new FileAction({
        id: id,
        displayName: (files, view) => displayName,
        title: (files, view) => displayName,
        iconSvgInline: (files, view) => FileCog,
        enabled: (files, view) => {
            return script === null || scriptAllowedForNodes(script, files);
        },
        order: order,
        async exec(file, view, dir) {
            handleExec(myHandler, [file], view, dir);
            return null;
        },
        async execBatch(files, view, dir) {
            handleExec(myHandler, files, view, dir);
            return [null];
        },
    });
}
function nodeFromLegacy(file) {
    return {
        get fileid() {
            return file.id;
        },
        get basename() {
            return file.name;
        },
        get path() {
            return file.path;
        }
    };
}
function registerPublicSharingFileAction(action, script) {
    if (!OCA.Sharing || !OCA.Sharing.PublicApp || !OCA.Sharing.PublicApp.fileList) {
        return;
    }
    let mimes = ["all"];
    if (script) {
        mimes = script.fileTypes;
    }
    for (const mime of mimes) {
        const legacyAction = {
            name: action.id,
            displayName: action.displayName(null, null),
            mime: mime,
            mimetype: mime,
            permissions: OC.PERMISSION_READ,
            order: 1001,
            iconClass: 'icon-files_scripts',
            // For multi-file picker
            action: (files) => {
                const nodes = files.map(nodeFromLegacy);
                action.execBatch(nodes, null, null);
            },
            // For single-file picker
            actionHandler: (filePath, context) => {
                var _a;
                const file = (_a = context === null || context === void 0 ? void 0 : context.fileInfoModel) === null || _a === void 0 ? void 0 : _a.attributes;
                if (!file) {
                    console.log("[files_scripts] Failed to find file for action handler.");
                    return;
                }
                const node = nodeFromLegacy(file);
                action.exec(node, null, null);
            },
        };
        OCA.Sharing.PublicApp.fileList.registerMultiSelectFileAction(legacyAction);
        OCA.Sharing.PublicApp.fileList.fileActions.registerAction(legacyAction);
    }
}
export function registerMenuOption(handler, script = null) {
    const actionObject = buildActionObject(handler, script);
    registerFileAction(actionObject);
    // FIXME: 	Public shares are still using the legacy file viewer, if it ever updates to the Vue version
    //  		we should update this code.
    setTimeout(() => {
        registerPublicSharingFileAction(actionObject, script);
    }, 2000);
}
