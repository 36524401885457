import { NcButton, NcModal, NcSelect } from "@nextcloud/vue";
import FileCog from 'vue-material-design-icons/FileCog.vue';
import ConsoleLine from 'vue-material-design-icons/ConsoleLine.vue';
import Play from 'vue-material-design-icons/Play.vue';
import Folder from 'vue-material-design-icons/Folder.vue';
import { api } from '../api/script';
import { translate as t } from '../l10n';
import { registerMenuOption, reloadDirectory } from '../files';
import ScriptInputComponent from '../components/ScriptSelect/ScriptInputComponent.vue';
import { MessageType, showMessage } from "../types/Messages";
import { scriptAllowedForNodes } from "../types/script";
export default {
    name: 'ScriptSelect',
    components: {
        NcModal,
        NcButton,
        NcSelect,
        FileCog,
        ConsoleLine,
        Folder,
        Play,
        ScriptInputComponent
    },
    data() {
        return {
            showModal: false,
            isRunning: false,
            selectedScript: null,
            selectedFiles: [],
            currentFolder: null,
            outputDirectory: null,
            scriptInputs: [],
            loadingScriptInputs: false,
            showScriptSelection: true
        };
    },
    computed: {
        scripts() {
            return this.allScripts.filter(s => scriptAllowedForNodes(s, this.selectedFiles));
        },
        allScripts() {
            return this.$store.getters.getEnabledScripts;
        },
        selectedDescription() {
            return this.selectedScript ? this.selectedScript.description : '';
        },
        readyToRun() {
            return this.selectedScript && !this.loadingScriptInputs && !this.isRunning;
        },
    },
    watch: {
        showModal(newVal) {
            if (newVal === true && !this.scripts) {
                this.$store.dispatch('fetchScripts');
            }
        },
    },
    mounted() {
        this.$store.dispatch('fetchScripts')
            .then(this.attachMenuOption);
    },
    methods: {
        t,
        closeModal() {
            this.showModal = false;
            this.isRunning = false;
            this.selectedScript = null;
            this.selectedFiles = null;
            this.currentFolder = null;
            this.scriptInputs = [];
        },
        async selectScript(script) {
            this.selectedScript = script;
            this.outputDirectory = this.selectedFiles[0].dirname;
            this.loadingScriptInputs = true;
            this.scriptInputs = script ? await api.getScriptInputs(script.id) : [];
            this.loadingScriptInputs = false;
        },
        async run() {
            var _a, _b, _c, _d;
            if (this.isRunning) {
                return;
            }
            this.isRunning = true;
            let messages = [];
            let view_files = [];
            try {
                let response = await api.runScript(this.selectedScript, this.scriptInputs, this.selectedFiles);
                reloadDirectory(this.currentFolder);
                view_files = (_a = response.view_files) !== null && _a !== void 0 ? _a : [];
                messages = (_b = response.messages) !== null && _b !== void 0 ? _b : [];
                messages.push({ message: (t('files_scripts', 'Action completed!')), type: MessageType.SUCCESS });
                this.closeModal();
            }
            catch (response) {
                const errorObj = (_c = response === null || response === void 0 ? void 0 : response.response) === null || _c === void 0 ? void 0 : _c.data;
                const errorMsg = (errorObj && errorObj.error) ? errorObj.error : t('files_scripts', 'Action failed unexpectedly.');
                messages = (_d = errorObj === null || errorObj === void 0 ? void 0 : errorObj.messages) !== null && _d !== void 0 ? _d : [];
                messages.push({ message: errorMsg, type: MessageType.ERROR });
            }
            this.isRunning = false;
            for (let message of messages) {
                showMessage(message);
            }
            if (view_files.length > 0) {
                OCA.Viewer.open({
                    fileInfo: view_files[0],
                    list: view_files,
                });
            }
        },
        /**
         * Select the files and optionally the script (if selecting directly from the file menu)
         */
        selectFiles(files, currentFolder) {
            this.selectedFiles = files;
            this.showScriptSelection = true;
            this.currentFolder = currentFolder;
            this.showModal = true;
        },
        selectFilesWithScript(script) {
            return (files) => {
                this.selectFiles(files);
                this.selectScript(script);
                this.showScriptSelection = false;
            };
        },
        attachMenuOption() {
            if (!this.allScripts || this.allScripts.length === 0) {
                console.debug("[files_scripts] No enabled scripts for this user, not attaching menu option.");
                return; // No enabled scripts: no need to attach the options
            }
            this.allScripts.forEach((script) => {
                if (script.showInContext) {
                    const selectFileFunc = this.selectFilesWithScript(script);
                    registerMenuOption(selectFileFunc, script);
                }
            });
            // Attach "More actions..." menu options
            registerMenuOption(this.selectFiles);
        },
    },
};
