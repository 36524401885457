import { NcActions, NcActionButton, NcButton } from '@nextcloud/vue';
import Plus from 'vue-material-design-icons/Plus.vue';
import TransferDown from 'vue-material-design-icons/TransferDown.vue';
import Delete from 'vue-material-design-icons/Delete.vue';
import { createScriptInput } from '../../types/script';
import Vue from 'vue';
import { api } from '../../api/script';
import { translate as t } from '../../l10n';
import EditInputDetails from './EditInputDetails.vue';
import { showInfo } from "@nextcloud/dialogs";
export default {
    name: 'EditInputs',
    components: {
        EditInputDetails,
        NcActions,
        NcActionButton,
        NcButton,
        Plus,
        Delete,
        TransferDown,
    },
    props: {
        scriptId: Number,
    },
    data() {
        return {
            inputName: '',
            inputDescription: '',
            scriptInputs: {},
            loading: true,
            editingInputName: null,
            editingInput: null
        };
    },
    mounted() {
        this.fetchInputs();
    },
    methods: {
        t,
        addInput() {
            this.edit(createScriptInput('', ''));
        },
        saveInput(scriptInput) {
            if (scriptInput.name === '') {
                showInfo(t('files_scripts', 'Script input name cannot be empty'));
                return;
            }
            if (scriptInput.name !== this.editingInputName && (scriptInput.name in this.scriptInputs)) {
                showInfo(t('files_scripts', 'Script input name already in use.'));
                return;
            }
            if (scriptInput.name !== this.editingInputName) {
                Vue.delete(this.scriptInputs, this.editingInputName);
            }
            this.scriptInputs[scriptInput.name] = scriptInput;
            this.resetEditing();
            this.updated();
        },
        resetEditing() {
            this.editingInput = null;
            this.editingInputName = null;
        },
        edit(scriptInput) {
            this.editingInput = Object.assign({}, scriptInput);
            this.editingInputName = scriptInput.name;
        },
        remove(scriptInput) {
            Vue.delete(this.scriptInputs, scriptInput.name);
            this.updated();
        },
        moveToLast(scriptInput) {
            this.remove(scriptInput);
            this.saveInput(scriptInput);
        },
        updated() {
            this.$emit('changed', this.scriptInputs);
        },
        async fetchInputs() {
            if (this.scriptId === null) {
                this.loading = false;
                return;
            }
            const inputs = await api.getScriptInputs(this.scriptId);
            const scriptInputs = {};
            inputs.forEach(function (input) {
                scriptInputs[input.name] = input;
            });
            this.scriptInputs = scriptInputs;
            this.loading = false;
            this.updated();
        },
    },
};
