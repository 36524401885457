import { NcSelect } from '@nextcloud/vue';
export default {
    name: 'FreeSelect',
    model: {
        prop: 'values',
        event: 'change'
    },
    components: {
        NcSelect
    },
    props: {
        label: String,
        hint: String,
        values: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            selected: [...this.values],
            options: []
        };
    },
    methods: {
        updateInput(input) {
            this.options = !!(input.trim()) ? [input] : [];
        },
        change() {
            this.$emit("change", this.selected);
        }
    },
    watch: {
        values() {
            this.selected = this.values;
        }
    }
};
