import { showError, showSuccess, showWarning, showInfo } from '@nextcloud/dialogs';
export var MessageType;
(function (MessageType) {
    MessageType["ERROR"] = "error";
    MessageType["WARNING"] = "warning";
    MessageType["INFO"] = "info";
    MessageType["SUCCESS"] = "success";
})(MessageType || (MessageType = {}));
export function getMessageType(type) {
    var _a;
    return (_a = MessageType[type]) !== null && _a !== void 0 ? _a : MessageType.INFO;
}
export function showMessage(message) {
    const options = {
        timeout: 10000
    };
    switch (message.type) {
        case MessageType.ERROR:
            showError(message.message, options);
            return;
        case MessageType.WARNING:
            showWarning(message.message, options);
            return;
        case MessageType.SUCCESS:
            showSuccess(message.message, options);
            return;
        default:
            showInfo(message.message, options);
    }
}
