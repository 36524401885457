// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/codemirror/theme/material-darker.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/codemirror/theme/idea.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/codemirror/lib/codemirror.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../node_modules/css-loader/dist/cjs.js!../../css/codemirror.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-script-edit[data-v-5befcfc0]{display:flex}.script-details[data-v-5befcfc0]{flex:0 0 25%;height:90vh;padding:6px;overflow-y:auto;overflow-x:clip}.section-description[data-v-5befcfc0]{opacity:.7;margin-bottom:16px}.script-editor[data-v-5befcfc0]{flex:1 0;border:solid 1px #708090;height:90vh}.input-script-name[data-v-5befcfc0]{width:100%;font-size:large}.input-script-description[data-v-5befcfc0]{width:100%;resize:vertical}.multi-input-groups[data-v-5befcfc0]{width:100%}`, "",{"version":3,"sources":["webpack://./src/components/ScriptEdit.vue"],"names":[],"mappings":"AAOA,wCACC,YAAA,CAGD,iCACC,YAAA,CACA,WAAA,CACA,WAAA,CACA,eAAA,CACA,eAAA,CAGD,sCACC,UAAA,CACA,kBAAA,CAGD,gCACC,QAAA,CACA,wBAAA,CACA,WAAA,CAGD,oCACC,UAAA,CACA,eAAA,CAGD,2CACC,UAAA,CACA,eAAA,CAGD,qCACC,UAAA","sourcesContent":["\n@import 'codemirror/theme/material-darker.css';\n@import 'codemirror/theme/idea.css';\n\n@import 'codemirror/lib/codemirror.css';\n@import '../../css/codemirror.css';\n\n.container-script-edit {\n\tdisplay: flex;\n}\n\n.script-details {\n\tflex: 0 0 25%;\n\theight: 90vh;\n\tpadding: 6px;\n\toverflow-y: auto;\n\toverflow-x: clip;\n}\n\n.section-description {\n\topacity: .7;\n\tmargin-bottom: 16px;\n}\n\n.script-editor {\n\tflex: 1 0;\n\tborder: solid 1px slategray;\n\theight: 90vh;\n}\n\n.input-script-name {\n\twidth: 100%;\n\tfont-size: large;\n}\n\n.input-script-description {\n\twidth: 100%;\n\tresize: vertical;\n}\n\n.multi-input-groups {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
